export const testimonialData = [
  {
    name: "Safdar (Entesla Solutions)",
    review:
      "\" I had a really positive experience with (Ashwin and Zeb). Their team was attentive and knowledgeable, and they provided solutions that exceeded my expectations.The process was smooth, and I'm happy with the results. I'd recommend them to anyone looking for services. \"",
  },
  {
    name: "Just Baatein Podcast(YouTube)",
    review:
      '" Got lucky with an intro for our podcast when we came across Ashwin’s and Zeb\'s work! Not only was their work clean, they also knew what we wanted and were able to give us clarity on what would go well for us! And it did 😁 "',
  },
  {
    name: " Mayankesh - Digify Digital ( Upwork)",
    review:
      '" Working with Ashwin and Zeb has been a breeze. I assigned them a complex transformation simulation and 3D modeling of T-shirts in Houdini, and they went above and beyond to deliver them before the deadline. What sparked my interest was their file management and naming system. When the final product was delivered, it was well-organized and placed in a proper folder structure that allowed our team to easily plug and play the work he delivered. Regarding feedback, they executed exactly what was asked from them. I\'m looking forward to working with them again. "',
  },
];
