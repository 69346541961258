import React from "react";
import "./contactus.scss";

const ContactUs = ({
  contactScrollRef,
}: {
  contactScrollRef: React.MutableRefObject<null>;
}) => {
  return (
    <section className="contact-section" id="contact" ref={contactScrollRef}>
      <h1 className="title1">Get In Touch</h1>
      {/* <Text>Lorem ipsum dolor sit amet, consectetur adipisicing.</Text> */}
      {/* <div className=".icons">
            <a href="https://www.facebook.com/">
              {" "}
              <img src={Facebook} alt="Facebook" />
            </a>
            <a href="https://www.linkedin.com//">
              <img src={LinkedId} alt="LinkedId" />
            </a>
            <a href="https://twitter.com/">
              <img src={Twitter} alt="Twitter" />
            </a>
            <a href="https://www.instagram.com/">
              <img src={Instagram} alt="Instagram" />
            </a>
          </div> */}
      <form className="form-style">
        <div className=".contact-row">
          <input name="name" type="text" placeholder="your name" />
          <input
            name="phone"
            type="tel"
            placeholder="enter working phone no."
          />
        </div>
        <textarea
          name=""
          id=""
          cols={30}
          rows={2}
          placeholder="your message"
        ></textarea>
        <div style={{ margin: "0 auto" }}>
          <button
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </section>
  );
};

export default ContactUs;
