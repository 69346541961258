import "./hero.scss";
import logoGif from "../../assets/images/FINAL_LOGO.png";
import Typewriter from "../typewriter/typewriter";

interface HeroProps {
  backgroundImage: string;
  title: string;
  subtitle: string;
  isHome: boolean;
}

function Hero({ backgroundImage, title, subtitle, isHome }: HeroProps) {
  const url =
    "https://docs.google.com/forms/d/e/1FAIpQLSezbRGHy5TYoRTA_JqYqYb37aV9hhtoeuW29SQcGGYtRG4TkQ/viewform?pli=1&pli=1";

  const openInNewTab = (value: boolean) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <section className="banner">
      <div
        className="charter-sales-hero"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        }}
      >
        <div className="hero-content">
          {/* <img src={logoGif} alt={"Pink Pumpkin"} className="logo-img"></img> */}
          <h1 className="large-font">
            <Typewriter text={title} speed={150} pauseTime={5000} />
          </h1>
          <p>{subtitle}</p>
          <button className="reach-out" onClick={() => openInNewTab(true)}>
            Reach Out !!
          </button>
        </div>
      </div>
    </section>
  );
}

export default Hero;
